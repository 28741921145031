import React from "react";
import "./Stories.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import story1 from "../assets/images/story1.svg";
import story2 from "../assets/images/story2.svg";
import story3 from "../assets/images/story3.svg";
import story4 from "../assets/images/story4.svg";
import story5 from "../assets/images/story5.svg";

import str1050 from "../assets/images/1050.png";
import str1051 from "../assets/images/1051.png";
import str1052 from "../assets/images/1052.png";
import str1053 from "../assets/images/1053.png";
import str1054 from "../assets/images/1054.png";
import str1055 from "../assets/images/1055.jpeg";
import str1056 from "../assets/images/1056.png";
// import str1057 from "../assets/images/1057.png";
import str1058 from "../assets/images/1058.png";
import str1059 from "../assets/images/1059.png";
import ananya from "../assets/images/ananya.png";
import manav from "../assets/images/manav.png";
import str1111 from "../assets/images/1111.jpeg";
import str1112 from "../assets/images/1112.jpeg";


// const stories = [story1, story2, story3, story4, story5];
const stories = [
  str1111,
  str1112,
  str1050,
  str1051,
  str1052,
  str1053,
  str1054,
  str1055,
  str1056,
  str1058,
  str1059,
  ananya,
  manav,
];

const responsive = {
  a: {
    breakpoint: { max: 4000, min: 1597 },
    items: 5,
  },
  b: {
    breakpoint: { max: 1597, min: 1197 },
    items: 5,
  },
  superLargeDesktop: {
    breakpoint: { max: 1197, min: 1023 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const Stories = () => {
  return (
    <div className="stories__container">
      <div className="stories">
        <div className="stories__title-card">
          <p className="stories__text--reg">Previous</p>
          <span className="stories__text--bold">Stories</span>
        </div>
        <div className="previous_carousel">
          <Carousel
            // showDots={true}
            responsive={responsive}
            // centerMode={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            itemClass="slider__item"
          >
            {stories.map((item, index) => (
              <img
                src={item}
                style={{
                  width: "100%",
                  height: "500px",
                  objectFit: "contain",
                }}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Stories;
