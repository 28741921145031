import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose, faCoffee, faL, faSlash } from '@fortawesome/free-solid-svg-icons';

import Logo from "../assets/images/nav_logo.svg";
import logoWhite from "../assets/images/logoWhite.svg";

import "./Navbar.css";

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    return (
        <div className='navbar__container'>
            <div className='navbar'>
                <div className='navbar__logo'>
                    <img src={logoWhite} alt='Logo' className='navbar__logo--image-white' />
                </div>
                <div className='navbar__logo'>
                    <img src={Logo} alt='Logo' className='navbar__logo--image' />
                </div>
                <div className='navbar__links'>
                    <a href="#" className='web--link'>
                        Home
                    </a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform" target='blank' className='web--link' onClick={() => setIsMobileMenuOpen(false)}>
                        Contact Us
                    </a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform" target='blank' className='web--link-btn' onClick={() => setIsMobileMenuOpen(false)}>
                        Enroll Now!
                    </a>
                </div>
                <div className='navbar__ham' onClick={() => setIsMobileMenuOpen(true)}>
                    <FontAwesomeIcon icon={faBars} color='#fff' className='navbar__icon' />
                </div>

                {isMobileMenuOpen && (
                    <div className="modal-overlay">
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "30px"
                        }}>
                            <div className='navbar__logo'>
                                <img src={Logo} alt='Logo' style={{ width: "100px", marginTop: "5px" }} />
                            </div>
                            <FontAwesomeIcon icon={faSlash} onClick={() => setIsMobileMenuOpen(false)} className="close-icon" />
                        </div>
                        <div className="modal">
                            {/* <p className='mobile--link' onClick={() => setIsMobileMenuOpen(false)}>Home</p> */}
                            {/* <p className='mobile--link' onClick={() => setIsMobileMenuOpen(false)}>Contact Us</p> */}
                            <a href="#" className='mobile--link' onClick={() => setIsMobileMenuOpen(false)}>
                                Home
                            </a>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform" target='blank' className='mobile--link' onClick={() => setIsMobileMenuOpen(false)}>
                                Contact Us
                            </a>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform" target='blank' className='mobile--link' onClick={() => setIsMobileMenuOpen(false)}>
                                Enroll Now!
                            </a>
                            {/* <p className='mobile--link' onClick={() => setIsMobileMenuOpen(false)}>Enroll Now!</p> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar