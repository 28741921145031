import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import "./FAQAccordion.css";

const FAQAccordion = ({ title, text, index = 1 }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-accordion__container">
      <div className="faq-accordion-text">
        {index == 0 && <div className="adc"></div>}
        <div
          className="faq-accordion__title-card"
          onClick={toggleAccordion}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            columnGap: "15px",
          }}
        >
          <p className="faq--card-qtn">{title}</p>
          <FontAwesomeIcon
            icon={isOpen ? faMinus : faPlus}
            color="#FE8616"
            className="faq--icon"
          />
        </div>
      </div>
      {isOpen && (
        <div className="faq-accordion__content-card">
          <p>{text}</p>
        </div>
      )}
    </div>
  );
};

export default FAQAccordion;
