import React from "react";
import "./FAQ.css";
import FAQAccordion from "./FAQAccordion";

const FAQ = () => {
  return (
    <div className="faq__container">
      <div className="faq">
        <p className="faq__title--text">FAQ's</p>
        <div className="faq__question-container">
          <FAQAccordion
            title={
              "Who can participate in this RoboAI Industrial Training Program ?"
            }
            text={
              "Our RoboAI Industrial Training Program is open to anyone with a passion for robotics and a desire to learn. Participants can be students, working Professionals, or Individuals interested in the field of RoboAI Industrial Training Program."
            }
            index={0}
          />
          
          <FAQAccordion
            title={"What is the duration of the Industrial Training?"}
            text={
              "The duration of the Industrial Training may vary depending on the specific programme. Every programme will have its doubt-clearing sessions along with live sessions to clear each student’s problems and difficulties."
            }
          />
          <FAQAccordion
            title={" Is this training program suitable for beginners with no prior experience in robotics?"}
            text={
              "Yes, absolutely! Our training program is designed to be beginner-friendly, welcoming individuals from all backgrounds without any prior experience in robotics. You'll find it accessible and engaging regardless of your field of expertise."
            }
          />
          <FAQAccordion
            title={"What support channels are available if I have questions or face technical difficulties during the training program?"}
            text={
              "Feel free to reach out to us via email with any questions or queries. Additionally, our dedicated event management team is available 24/7 to assist you throughout the training program and you could email: operations@myequation.in  We're here to ensure you have a smooth and successful learning experience!"
            }
          />
          <FAQAccordion
            title={"How will this training program help me in my career?"}
            text={"This training program teaches you important software like SolidWorks, Ansys , Easy Eda , KiCAD, Gazeebo , Conda etc and many more. You'll also learn how to use them in real-industry cases. This knowledge and experience will make you more valuable to employers."
            }
          />

          <FAQAccordion
            title={"Is there any age restriction for participation?"}
            text={
              "There is no age restriction for participation in the Industrial Training. Our Programs are designed to cater to a wide age range. While some programmes may have specific age requirements, we generally welcome participants of all ages."
            }
          />
          <FAQAccordion
            title={"How do I register for the RoboAI workshop?"}
            text={
              "To register for the RoboAI workshop, simply visit our website and navigate to the registration page. Fill out the necessary information and make the payment as instructed. Upon successful registration, you will receive a confirmation email with further details."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
