import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import "./Accordion.css";
import bg1 from "../assets/images/Group1221(1).svg";
import bg2 from "../assets/images/Group 1221 (2).svg";
import bg3 from "../assets/images/Group 1221 (3).svg";

const Accordion = ({ title, options, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="accordion__container"
      style={
        {
          // backgroundImage: `url(${bg1})`
        }
      }
    >
      <div className="accordion-text-bar">
        <div className="accordion-elps1"></div>
        <div className="accordion-elps2"></div>
        <p className="fugaz">{index + 1}</p>
        <div
          className="accordion__title-card"
          onClick={toggleAccordion}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            columnGap: "15px",
          }}
        >
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            color="#FE8616"
            className="accr--icon"
          />
          <p className="accr--title">{title}</p>
        </div>
      </div>
      {isOpen && (
        <div className="accordion__content-card">
          {options &&
            options.length > 0 &&
            options.map((item, index) => <p className="accr-desc">{item}</p>)}
        </div>
      )}
    </div>
  );
};

export default Accordion;
