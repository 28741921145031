import React from "react";
import "./Enroll.css";

import emoji1 from "../assets/images/emoji1.svg";
import emoji2 from "../assets/images/emoji2.svg";
import emoji3 from "../assets/images/emoji3.svg";
import emoji4 from "../assets/images/emoji4.svg";
import bookmark from "../assets/images/bookmark.svg";
import bookmarkBig from "../assets/images/bookmarkBig.svg";
import tick from "../assets/images/tick.svg";
import enroll from "../assets/images/enroll.svg";
import pay from "../assets/images/pay.svg";
import perkRound from "../assets/images/perkRound.svg";
import quot from "../assets/images/quot.svg";

import alexa from "../assets/images/alexa.svg";

const Enroll = () => {
  return (
    <div className="enroll__container">
      <div className="enroll">
        <div>
          <div className="enroll-title-block">
            <p className="enroll__title-textR">Why should you</p>
            <p className="enroll__title-textB">
              Enroll?
              <img src={quot} className="quot" />
            </p>
          </div>
          <div className="enroll__top">
            <div
              style={{
                position: "relative",
                // overflow: "hidden",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "6px",
                marginBottom: "15px",
              }}
              className="enroll__text-block"
            >
              <div className="enroll__text--elps1"></div>
              <div className="enroll__text--elps2"></div>

              <div className="enroll__text-card">
                <img className="bookmark-img" src={bookmark} />
                <p className="enroll-pc">Professional Certificates</p>
                <p className="enroll-3">3 Specialised Phases</p>
                <p className="enroll-e">
                  Earn a career credential that demonstrates your expertise.
                </p>
                <img src={bookmarkBig} className="book--big" />
              </div>
            </div>
            <div className="enroll__emoji-block">
              <div className="enroll__emoji-card">
                <img src={emoji1} />
                <div>
                  <p className="enroll__emoji-textB">Mentor Guidance</p>
                  <p className="enroll__emoji-textR">By Industry Experts</p>
                </div>
              </div>
              <div className="enroll__emoji-card">
                <img src={emoji2} />
                <div>
                  <p className="enroll__emoji-textB">45+T Days   Workshop</p>
                  <p className="enroll__emoji-textR">Intensified Learning</p>
                </div>
              </div>
              <div className="enroll__emoji-card">
                <img src={emoji3} />
                <div>
                  <p className="enroll__emoji-textB">Practical Projects</p>
                  <p className="enroll__emoji-textR">
                    Tackle Real-World Problems
                  </p>
                </div>
              </div>
              <div className="enroll__emoji-card">
                <img src={emoji4} />
                <div>
                  <p className="enroll__emoji-textB">3 Level Certification</p>
                  <p
                    className="enroll__emoji-textR"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    Ratified by Big Companies{" "}
                    {/* <img src={alexa} style={{ width: "50px" }} /> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="perk-round-block">
            <p className="enroll__title-text">& the</p>
            <p className="perk-round">Perks?</p>
          </div>

          <div className="enroll__perks-block">
            <div className="enroll__perks-card">
              <div className="enroll__perks-item">
                <img src={tick} />
                <p>1:1 Live Interactive Sessions</p>
              </div>
              <div className="enroll__perks-item">
                <img src={tick} />
                <p>Skill Building Network</p>
              </div>
              <div className="enroll__perks-item">
                <img src={tick} />
                <p>Doubt Support</p>
              </div>
            </div>
            <div className="enroll__perks-card">
              <div className="enroll__perks-item">
                <img src={tick} />
                <p>Employment Prospects</p>
              </div>
              <div className="enroll__perks-item">
                <img src={tick} />
                <p>Career Guidance</p>
              </div>
              <div className="enroll__perks-item">
                <img src={tick} />
                <p>Personalized Programme</p>
              </div>
            </div>
          </div>
        </div>

        <div className="enroll__button-container">
          {/* <a target='blank'
                        href='https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform'>
                        <img src={enroll} style={{
                            height: "30px",
                            width: "100%",
                        }} />
                    </a> */}
          {/* <img src={pay} style={{
                        height: "30px"
                    }} /> */}
          {/* <button className="enroll__btn-yellow">Enroll Now!</button> */}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform"
            target="blank"
            style={{ textDecoration: "none", color: "#242121" }}
          >
            <button className="enroll__btn-enroll">Enroll Now!</button>
          </a>
          <a
            href="https://pages.razorpay.com/ROBOAI-WebsiteMain"
            target="blank"
            style={{ textDecoration: "none", color: "#242121" }}
          >
            <button className="enroll__btn-pay">Pay Now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Enroll;
