import React from "react";
import "./HeroSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCoffee,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import herotext from "../assets/images/herotext.svg";
import heroImage from "../assets/images/heroImage.svg";
import download from "../assets/images/download.svg";
import Navbar from "./Navbar";

import enroll from "../assets/images/enroll.svg";
import pay from "../assets/images/pay.svg";

import robopdf from "../assets/roboai.pdf";

const HeroSection = () => {
  return (
    <div className="herosection__container">
      <Navbar />
      <div className="hero">
        <div className="herosection">
          <div className="herosection__top">
            <div className="herosection__text-block">
              <p className="herosection__text">Step into the future of</p>
              <p className="herosection__text">Robotics and AI with</p>
              <img src={herotext} className="herosection__image-text" />
            </div>
            <div className="herosection__image">
              <img src={heroImage} />
            </div>
          </div>

          <p className="herosection__sub-text">
            A 45+T Days Industrial Training Program on Robotics and AI to
            Future-proof your career.
          </p>
          <div className="herosection__bottom">
            <div className="herosection__btns">
              {/* <a className='hero__btn' target='blank'
                                href='https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform'>
                                <img src={enroll} />
                            </a>
                            <a className='hero__btn' href='https://pages.razorpay.com/website2' target='blank'>
                                <img src={pay} />
                            </a> */}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSc4iRVfo7cwfpj_yXkqktybzbUCQQcJGDz9Umym_CQVVrgPQA/viewform"
                target="blank"
                className="hero__enroll-btn"
              >
                Enroll Now!
              </a>
              <a
                href="https://pages.razorpay.com/ROBOAI-WebsiteMain"
                target="blank"
                className="hero__pay-btn"
              >
                Pay Now
              </a>
            </div>
            <div className="herosection__download">
              <p className="herosection__text--yellow">Download Brochure</p>
              <a
                className="herosection__download--icon"
                href={robopdf}
                target="blank"
              >
                <img src={download} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
