import React from "react";
import "./Mentees.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Ajinkya from "../assets/videos/Ajinkya";
import Haadi from "../assets/videos/Haadi";
import Hardik from "../assets/videos/Hardik.mp4";
import Pritha from "../assets/videos/Pritha";
import Kerry from "../assets/videos/Kerry";
import Rashmi from "../assets/videos/Rashmi.mp4";
import Supriyam from "../assets/videos/Supriyam.mp4";
import Unmona from "../assets/videos/Unmona";

const videos = [
  Ajinkya,
  Haadi,
  Hardik,
  Pritha,
  Kerry,
  Rashmi,
  Supriyam,
  Unmona,
];

const responsive = {
  a: {
    breakpoint: { max: 4000, min: 1597 },
    items: 5,
  },
  b: {
    breakpoint: { max: 1597, min: 1197 },
    items: 5,
  },
  superLargeDesktop: {
    breakpoint: { max: 1197, min: 1023 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const Mentees = () => {
  return (
    <div className="mentees__container">
      <div className="mentees">
        <div className="mentees__title-card">
          <p className="mentees__title--reg">Hear from our</p>
          <p className="mentees__title--bold">Mentees</p>
        </div>
        <div className="previous_carousel">
          <Carousel
            responsive={responsive}
            // centerMode={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            itemClass="slider__item"
          >
            {videos.map((item, index) => (
              <video
                width="100%"
                height="588"
                controls
                disablePictureInPicture
                controlslist="nodownload noplaybackrate"
              >
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Mentees;
