import React from "react";
import "./Footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTicket } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedin,
  faInstagram,
  faInstagramSquare,
  faFacebook,
  faFacebookSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";

import footerLogo from "../assets/images/footer-logo.svg";

const Footer = () => {
  return (
    <div className="footer__container">
      <div className="footer">
        <div className="footer--image-block">
          <img src={footerLogo} className="footer__logo-image" />
          <p className="foot--txt">
            India's only startup to provide an ecosystem for core engineers
          </p>
          <div></div>
        </div>
        <div className="footer--middle">
          <div className="footer__contact-container">
            <div className="footer__contact-card">
              <p>Gunesh - 8806806479</p>
              <p>(SMO Head)</p>
            </div>
            <div className="footer__contact-card">
              <p>Kaustuv Mohanty - 9337837362</p>
              <p>(OutReach)</p>
            </div>
          </div>

          <div
            className="footer-Follow-us"
            style={{
              display: "flex",
              // flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: "40px",
            }}
          >
            <p
              className="footer-follow-text"
              style={{
                textAlign: "center",
              }}
            >
              Follow us on !
            </p>
            <div className="footer__brand--container">
              <FontAwesomeIcon
                icon={faInstagramSquare}
                color="#fff"
                className="footer__brand-icon"
              />
              <FontAwesomeIcon
                icon={faFacebookSquare}
                color="#fff"
                className="footer__brand-icon"
              />
              <FontAwesomeIcon
                icon={faYoutubeSquare}
                color="#fff"
                className="footer__brand-icon"
              />
              <FontAwesomeIcon
                icon={faTwitter}
                color="#fff"
                className="footer__brand-icon"
              />
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                / My Equation
              </p>
            </div>
          </div>
        </div>
        <p
          className="footer-copyright-text"
          style={{
            textAlign: "center",
          }}
        >
          MyEquationTM is a registered trademark under Tech Analogy Pvt Ltd. All rights reserved || 
          <a href="https://drive.google.com/file/d/1dOmgPLh-Wx378twn0IOIw45OBFZ8uN0G/view?usp=sharing">
            <b>
              <u> Terms and Conditions </u>
            </b>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
